import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout pageInfo={{ pageName: "page-2" }}>
    <SEO title="Page two" />
    <h1>About</h1>
    <p>The flag is here somewhere on the site:</p>
    
    <p>
    If you followed the directions, the following two questions can be answered:
    </p>
    <p> - What technologies is Learn About Security made with? </p>
    <p> - What are the Domain siblings? </p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
